import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slider.css';
import { useEffect } from "react";
import axiosClient from "../../../axios-client";
import { useState } from "react";
import { Link } from "react-router-dom";
import {useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const HeroSlider = (props) => {
  

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.play();
    }
  }, []);

  const { t } = useTranslation()
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    appendDots: (dots) => {
      return <ul style={{ margin: "0px" }}>{dots}</ul>
    },
  }
  const basepath=useSelector((state) => state.setting.basepath);


  
  
  return (
    <section className="hero__slider">
      {/* {props.offerBanner.length === 1 && props.offerBanner[0].media_type === 'video' ? (
        <Link 
          className="box d-flex justify-content-between"
        >
          <video autoplay loop muted width='850px' height='400px' ref={videoRef} style={{ objectFit: 'cover' }}>
            <source src={`${basepath}/${props.offerBanner[0].banner_image}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Link>

      ) : ( */}
        <Slider {...settings}>
          {props.offerBanner && Array.isArray(props.offerBanner) && props.offerBanner.map((value, index) => {
            const backgroundImage = `${basepath}/${value.banner_image}`;
            const videoSource = `${basepath}/${value.banner_image}`;
            const hasVideo = value.media_type === 'video';

            return (
              <Link 
                to={value.product_id ? `/shop/${value.product_id}` : '#'} 
                className="box d-flex justify-content-between" 
                key={index}
              >
                {hasVideo ? (
                  <video 
                    autoPlay 
                    loop 
                    muted 
                    height="400px" 
                    ref={videoRef} 
                    style={{ objectFit: 'cover', width: '100%', maxHeight: '400px'}}
                  >
                    <source src={videoSource} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={backgroundImage} alt="Offer Banner" />
                )}
                <div className="hero_slider_content">
                  <h1>{value.offer_name || 'Default Offer Name'}</h1>
                </div>
              </Link>
            );
          })}
        </Slider>
      {/* )} */}
      
    </section>
  )
}

export default HeroSlider
